<template>
  <DisplayContainer label=" Verträge">
    <template>
      <v-expansion-panels focusable>
        <v-expansion-panel
          v-for="(contractId, index) in Object.keys(contractData)"
          :key="index"
        >
          <v-expansion-panel-header>
            <div class="d-flex">
              <div class="px-2 mr-auto">
                <span class="font-weight-bold">{{ contractId }}</span>
                &nbsp; &nbsp;
                <span v-if="contractData[contractId].internetMainProduct">
                  {{ contractData[contractId].internetMainProduct.name }}
                </span>
                <span v-else>
                  {{ contractData[contractId].addressShortDto.compactAddress }}
                </span>
              </div>
              <div
                v-if="phaseInPermission && phaseInPossible(contractId)"
                class="pase-in-icon px-4"
              >
                <v-btn
                  title="Vertrag einphasen"
                  x-small
                  icon
                  :loading="phaseInPossibleLoading || parseInt(phaseInRunning) === parseInt(contractId)"
                  :disabled="phaseInPossibleLoading || parseInt(phaseInRunning) === parseInt(contractId)"
                  @click.native.stop="phaseInContract(contractId)"
                >
                  <v-icon dark>
                    mdi-play
                  </v-icon>
                </v-btn>
              </div>
            </div>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <div class="space"></div>
            <v-tabs
              v-model="tab"
              fixed-tabs
              slider-size="5"
              slider-color="#A5E3F7"
              background-color="#2270C4"
              dark
            >
              <v-tab
                v-for="tabItem in tabItems"
                :key="tabItem"
              >
                {{ tabItem }}
              </v-tab>
            </v-tabs>
            <br />
            <v-tabs-items
              :key="contractId"
              v-model="tab"
              class="divTabItems"
            >
              <v-tab-item>
                <div class="input-row">
                  <NameDisplay :addressDto="contractData[contractId].addressShortDto" />
                  <AddressDisplay :addressDto="contractData[contractId].addressShortDto" />
                  <DateDisplay
                    label="Aktivierungsdatum"
                    :date="contractData[contractId].activeDate"
                  />
                  <DateDisplay
                    label="Deaktivierungsdatum"
                    :date="contractData[contractId].deactiveDate"
                  />
                  <div>
                    <DataDisplay label="Tarifberatung info">
                      <span v-if="contractData[contractId].tkgInfo">{{
                        contractData[contractId].tkgInfo
                      }}</span>
                    </DataDisplay>
                    <DataDisplay label="Datum Tarifberatung">
                      <span v-if="contractData[contractId].tkgAdvisoryDate">{{
                        formatDate(contractData[contractId].tkgAdvisoryDate)
                      }}</span>
                    </DataDisplay>
                  </div>
                  <div>
                    <DataDisplay label="ReferenzId">
                      <span v-if="contractData[contractId].referenceId">{{
                        contractData[contractId].referenceId
                      }}</span>
                    </DataDisplay>
                    <DataDisplay label="Projekt ID">
                      <span v-if="contractData[contractId].addressShortDto.objectInfo !== null && contractData[contractId].addressShortDto.objectInfo.projectId">{{
                        contractData[contractId].addressShortDto.objectInfo.projectId
                      }}</span>
                    </DataDisplay>
                    <DataDisplay label="AGS27">
                      <span v-if="contractData[contractId].addressShortDto.objectInfo !== null && contractData[contractId].addressShortDto.objectInfo.ags27">{{
                        contractData[contractId].addressShortDto.objectInfo.ags27
                      }}</span>
                    </DataDisplay>
                  </div>
                  <div>
                    <DateDisplay
                      label="Datum Widerruf"
                      :date="contractData[contractId].cancellationDate"
                    />
                    <DataDisplay label="Kündigungsgrund">
                      <span v-if="contractData[contractId].reasonForTermination">{{
                        contractData[contractId].reasonForTermination
                      }}</span>
                    </DataDisplay>
                  </div>
                  <DateDisplay
                    label="Datum Auftragsbestätigung"
                    :date="contractData[contractId].confirmationDat"
                  />
                  <DataDisplay label="Vertragserfasser">
                    <span v-if="contractData[contractId].contractOrderCreator">{{ contractData[contractId].contractOrderCreator }}</span>
                  </DataDisplay>
                  <DataDisplay label="Vertragsherkunft">
                    <span v-if="contractData[contractId].contractOrderOriginType">{{
                      contractData[contractId].contractOrderOriginType
                    }}</span>
                  </DataDisplay>
                  <DataDisplay label="Blocking Information">
                    <span v-if="contractData[contractId].lockStatusSince">gesperrt seit
                      {{
                        formatDate(contractData[contractId].lockStatusSince)
                      }}</span>
                  </DataDisplay>
                </div>
              </v-tab-item>
              <v-tab-item style="overflow: auto">
                <AllOrderedProductsTable :allProducts="contractData[contractId].products" />
              </v-tab-item>
              <v-tab-item>
                <div class="input-row">
                  <DataDisplay label="Bestandteil der Tarife">
                    {{
                      contractData[contractId].slaProductData
                        ? contractData[contractId].slaProductData.tarif
                        : null
                    }}
                  </DataDisplay>
                  <DataDisplay label="Störungsmeldung">
                    {{
                      contractData[contractId].slaProductData
                        ? contractData[contractId].slaProductData.errorMessage
                        : null
                    }}
                  </DataDisplay>
                  <DataDisplay label="Kontakt für Störungsmeldungen">
                    <v-icon color="green">
                      mdi-phone
                    </v-icon>
                    <span>
                      {{
                        contractData[contractId].slaProductData
                          ? contractData[
                            contractId
                          ].slaProductData.contact.split('und')[0]
                          : null
                      }}
                    </span>
                    <br />
                    <v-icon color="blue">
                      mdi-email
                    </v-icon>
                    <span>
                      {{
                        contractData[contractId].slaProductData
                          ? contractData[
                            contractId
                          ].slaProductData.contact.split('und')[1]
                          : null
                      }}
                    </span>
                  </DataDisplay>
                  <DataDisplay label="Reaktionszeit">
                    {{
                      contractData[contractId].slaProductData
                        ? contractData[contractId].slaProductData.reactionTime
                        : null
                    }}
                  </DataDisplay>
                  <DataDisplay label="Servicezeit">
                    {{
                      contractData[contractId].slaProductData
                        ? contractData[contractId].slaProductData.serviceTime
                        : null
                    }}
                  </DataDisplay>
                  <DataDisplay label="Wiederherstellungszeit (TTR)">
                    {{
                      contractData[contractId].slaProductData
                        ? contractData[contractId].slaProductData
                          .restorationTime
                        : null
                    }}
                  </DataDisplay>
                  <DataDisplay label="Verfügbarkeit">
                    {{
                      contractData[contractId].slaProductData
                        ? contractData[contractId].slaProductData.availability
                        : null
                    }}
                  </DataDisplay>
                  <DataDisplay label="Dienstüberwachung">
                    {{
                      contractData[contractId].slaProductData
                        ? contractData[contractId].slaProductData
                          .serviceMonitoring
                        : null
                    }}
                  </DataDisplay>
                </div>
              </v-tab-item>
              <v-tab-item>
                <div v-if="
                    contractData[contractId].provisioningData &&
                      contractData[contractId].provisioningData &&
                      !provisioningDataLoading
                  ">
                  <v-simple-table>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left">
                            Vertragsinfo
                          </th>
                          <th class="text-left">
                            MSAN
                          </th>
                          <th class="text-left">
                            Radius
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td class="top">
                            <ul>
                              <li>
                                <DataDisplay label="Internet-Hauptprodukt">
                                  <div v-if="
                                      contractData[contractId]
                                        .provisioningData &&
                                        contractData[contractId].provisioningData
                                          .internetProvisioning
                                          .internetMainProduct
                                    ">
                                    <div>
                                      {{
                                        contractData[contractId]
                                          .provisioningData.internetProvisioning
                                          .internetMainProduct.name
                                      }}
                                    </div>
                                  </div>
                                </DataDisplay>
                              </li>
                              <li>
                                <div
                                  v-if="
                                    contractData[contractId].provisioningData &&
                                      contractData[contractId].provisioningData
                                        .msanProvisioning.ontProductWithDevice &&
                                      contractData[contractId].provisioningData
                                        .msanProvisioning.ontProductWithDevice
                                        .device &&
                                      contractData[contractId].provisioningData
                                        .msanProvisioning.ontProductWithDevice
                                        .product
                                  "
                                  class="label"
                                >
                                  {{
                                    contractData[contractId].provisioningData
                                      .msanProvisioning.ontProductWithDevice
                                      .product.name
                                  }}
                                </div>
                                <div
                                  v-else
                                  class="label"
                                >
                                  Kein ONT-Produkt zugewiesen
                                  <br />
                                  <slot> - </slot>
                                </div>

                                <div v-if="
                                    contractData[contractId].provisioningData &&
                                      contractData[contractId].provisioningData
                                        .msanProvisioning.ontProductWithDevice &&
                                      contractData[contractId].provisioningData
                                        .msanProvisioning.ontProductWithDevice
                                        .device
                                  ">
                                  <ul>
                                    <li>
                                      <v-tooltip bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                          <v-icon
                                            color="primary"
                                            dark
                                            v-bind="attrs"
                                            v-on="on"
                                          >
                                            mdi-checkbox-multiple-blank-circle-outline
                                          </v-icon>
                                        </template>
                                        <span>Model</span>
                                      </v-tooltip>
                                      {{
                                        contractData[contractId]
                                          .provisioningData.msanProvisioning
                                          .ontProductWithDevice.device.model
                                      }}
                                    </li>
                                    <li>
                                      <v-tooltip bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                          <v-icon
                                            color="primary"
                                            dark
                                            v-bind="attrs"
                                            v-on="on"
                                          >
                                            mdi-id-card
                                          </v-icon>
                                        </template>
                                        <span>Seriennummer</span>
                                      </v-tooltip>
                                      {{
                                        contractData[contractId]
                                          .provisioningData.msanProvisioning
                                          .ontProductWithDevice.device
                                          .serialNumber
                                      }}
                                    </li>
                                  </ul>
                                </div>
                              </li>
                              <li>
                                <div
                                  v-if="
                                    contractData[contractId].provisioningData &&
                                      contractData[contractId].provisioningData
                                        .msanProvisioning.cpeProductWithDevice &&
                                      contractData[contractId].provisioningData
                                        .msanProvisioning.cpeProductWithDevice
                                        .device &&
                                      contractData[contractId].provisioningData
                                        .msanProvisioning.cpeProductWithDevice
                                        .product
                                  "
                                  class="label"
                                >
                                  {{
                                    contractData[contractId].provisioningData
                                      .msanProvisioning.cpeProductWithDevice
                                      .product.name
                                  }}
                                </div>
                                <div
                                  v-else
                                  class="label"
                                >
                                  Kein Router-Produkt zugewiesen
                                  <br />
                                  <slot>-</slot>
                                </div>

                                <div v-if="
                                    contractData[contractId].provisioningData &&
                                      contractData[contractId].provisioningData
                                        .msanProvisioning.cpeProductWithDevice &&
                                      contractData[contractId].provisioningData
                                        .msanProvisioning.cpeProductWithDevice
                                        .device
                                  ">
                                  <ul>
                                    <li>
                                      <v-tooltip bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                          <v-icon
                                            color="primary"
                                            dark
                                            v-bind="attrs"
                                            v-on="on"
                                          >
                                            mdi-checkbox-multiple-blank-circle-outline
                                          </v-icon>
                                        </template>
                                        <span>Model</span>
                                      </v-tooltip>
                                      {{
                                        contractData[contractId]
                                          .provisioningData.msanProvisioning
                                          .cpeProductWithDevice.device.model
                                      }}
                                    </li>
                                    <li>
                                      <v-tooltip bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                          <v-icon
                                            color="primary"
                                            dark
                                            v-bind="attrs"
                                            v-on="on"
                                          >
                                            mdi-id-card
                                          </v-icon>
                                        </template>
                                        <span>Seriennummer</span>
                                      </v-tooltip>
                                      {{
                                        contractData[contractId]
                                          .provisioningData.msanProvisioning
                                          .cpeProductWithDevice.device
                                          .serialNumber
                                      }}
                                    </li>
                                  </ul>
                                </div>
                              </li>
                            </ul>
                          </td>
                          <td class="top">
                            <ul>
                              <li>
                                <DataDisplay label="GPON-Hierarchie">
                                  <div v-if="
                                      contractData[contractId]
                                        .provisioningData &&
                                        contractData[contractId].provisioningData
                                          .msanProvisioning &&
                                        contractData[contractId].provisioningData
                                          .msanProvisioning.uniPortInformation
                                    ">
                                    <div>
                                      {{
                                        contractData[contractId]
                                          .provisioningData.msanProvisioning
                                          .uniPortInformation.gponHierarchy
                                      }}
                                    </div>
                                  </div>
                                </DataDisplay>
                              </li>

                              <li>
                                <DataDisplay label="Registrierungsstatus">
                                  <div
                                    v-if="
                                      contractData[contractId].provisioningData
                                        .msanProvisioning
                                        .provisioningInformation &&
                                        (contractData[contractId].provisioningData
                                          .msanProvisioning
                                          .provisioningInformation
                                          .msanIsAlreadyProvisioned ||
                                          contractData[contractId]
                                            .provisioningData.msanProvisioning
                                            .provisioningInformation
                                            .msanStatus === 'up')
                                    "
                                    class="align-elements margin-bottom-1"
                                  >
                                    <v-tooltip top>
                                      <template v-slot:activator="{ on, attrs }">
                                        <v-icon
                                          v-bind="attrs"
                                          v-on="on"
                                        >
                                          mdi-web-check
                                        </v-icon>
                                      </template>
                                      <span>
                                        MSAN-Status:
                                        {{
                                          contractData[contractId]
                                            .provisioningData.msanProvisioning
                                            .provisioningInformation.msanStatus
                                        }}
                                      </span>
                                    </v-tooltip>
                                    &nbsp; Registriert
                                  </div>
                                  <div
                                    v-else
                                    class="align-elements margin-bottom-1"
                                  >
                                    <v-tooltip top>
                                      <template v-slot:activator="{ on, attrs }">
                                        <v-icon
                                          v-bind="attrs"
                                          v-on="on"
                                        >
                                          mdi-web-off
                                        </v-icon>
                                      </template>
                                      <span>
                                        MSAN wurde noch nicht provisioniert
                                      </span>
                                      <span v-if="
                                          contractData[contractId]
                                            .provisioningData.msanProvisioning
                                            .provisioningInformation
                                        ">
                                        MSAN-Status:
                                        {{
                                          contractData[contractId]
                                            .provisioningData.msanProvisioning
                                            .provisioningInformation.msanStatus
                                        }}
                                      </span>
                                    </v-tooltip>
                                    &nbsp; Nicht registriert
                                  </div>
                                </DataDisplay>
                              </li>
                              <li>
                                <DataDisplay label="Signallevel Empfang">
                                  <div
                                    v-if="
                                      contractData[contractId].provisioningData
                                        .msanProvisioning
                                        .provisioningInformation &&
                                        contractData[contractId].provisioningData
                                          .msanProvisioning
                                          .provisioningInformation
                                          .portReceiveSignalLevelDbm
                                    "
                                    class="align-elements"
                                  >
                                    {{
                                      contractData[contractId].provisioningData
                                        .msanProvisioning
                                        .provisioningInformation
                                        .portReceiveSignalLevelDbm
                                    }}
                                    dBm
                                  </div>
                                </DataDisplay>
                              </li>
                              <li>
                                <DataDisplay label="Entfernung zum OLT">
                                  <div
                                    v-if="
                                      contractData[contractId].provisioningData
                                        .msanProvisioning
                                        .provisioningInformation &&
                                        contractData[contractId].provisioningData
                                          .msanProvisioning
                                          .provisioningInformation
                                          .portDistanceToOltKm
                                    "
                                    class="align-elements"
                                  >
                                    {{
                                      contractData[contractId].provisioningData
                                        .msanProvisioning
                                        .provisioningInformation
                                        .portDistanceToOltKm
                                    }}
                                    km
                                  </div>
                                </DataDisplay>
                              </li>
                              <div v-if="
                                  contractData[contractId].provisioningData
                                    .msanProvisioning.provisioningInformation
                                ">
                                <li>
                                  <DataDisplay label="PON-Portstatus Admin / Operating">
                                    <div
                                      v-if="
                                        contractData[contractId]
                                          .provisioningData.msanProvisioning
                                          .provisioningInformation
                                      "
                                      class="align-elements"
                                    >
                                      <span v-if="
                                          contractData[contractId]
                                            .provisioningData.msanProvisioning
                                            .provisioningInformation
                                            .portAdminStatus
                                        ">{{
                                        contractData[contractId]
                                          .provisioningData.msanProvisioning
                                          .provisioningInformation
                                          .portAdminStatus
                                      }}</span>
                                      <v-icon
                                        v-if="
                                          contractData[contractId]
                                            .provisioningData.msanProvisioning
                                            .provisioningInformation
                                            .portAdminStatus === 'up'
                                        "
                                        color="green darken-2"
                                      >
                                        mdi-trending-up
                                      </v-icon>
                                      <v-icon
                                        v-else
                                        color="red"
                                      >
                                        mdi-trending-down
                                      </v-icon>
                                      &nbsp; /&nbsp;
                                      <span v-if="
                                          contractData[contractId]
                                            .provisioningData.msanProvisioning
                                            .provisioningInformation
                                            .portOperatingStatus
                                        ">{{
                                        contractData[contractId]
                                          .provisioningData.msanProvisioning
                                          .provisioningInformation
                                          .portOperatingStatus
                                      }}</span>
                                      <v-icon
                                        v-if="
                                          contractData[contractId]
                                            .provisioningData.msanProvisioning
                                            .provisioningInformation
                                            .portOperatingStatus === 'up'
                                        "
                                        color="green darken-2"
                                      >
                                        mdi-trending-up
                                      </v-icon>

                                      <v-icon
                                        v-else
                                        color="red"
                                      >
                                        mdi-trending-down
                                      </v-icon>
                                    </div>
                                  </DataDisplay>
                                </li>
                                <li>
                                  <DataDisplay label="UNI-Portstatus Admin / Operating">
                                    <div
                                      v-if="
                                        contractData[contractId]
                                          .provisioningData.msanProvisioning
                                          .provisioningInformation
                                      "
                                      class="align-elements"
                                    >
                                      <span v-if="
                                          contractData[contractId]
                                            .provisioningData.msanProvisioning
                                            .provisioningInformation
                                            .uniPortAdminStatus
                                        ">{{
                                        contractData[contractId]
                                          .provisioningData.msanProvisioning
                                          .provisioningInformation
                                          .uniPortAdminStatus
                                      }}</span>
                                      <v-icon
                                        v-if="
                                          contractData[contractId]
                                            .provisioningData.msanProvisioning
                                            .provisioningInformation
                                            .uniPortAdminStatus === 'up'
                                        "
                                        color="green darken-2"
                                      >
                                        mdi-trending-up
                                      </v-icon>
                                      <v-icon
                                        v-else
                                        color="red"
                                      >
                                        mdi-trending-down
                                      </v-icon>
                                      &nbsp; /&nbsp;
                                      <span v-if="
                                          contractData[contractId]
                                            .provisioningData.msanProvisioning
                                            .provisioningInformation
                                            .uniPortOperatingStatus
                                        ">{{
                                        contractData[contractId]
                                          .provisioningData.msanProvisioning
                                          .provisioningInformation
                                          .uniPortOperatingStatus
                                      }}</span>
                                      <v-icon
                                        v-if="
                                          contractData[contractId]
                                            .provisioningData.msanProvisioning
                                            .provisioningInformation
                                            .uniPortOperatingStatus === 'up'
                                        "
                                        color="green darken-2"
                                      >
                                        mdi-trending-up
                                      </v-icon>

                                      <v-icon
                                        v-else
                                        color="red"
                                      >
                                        mdi-trending-down
                                      </v-icon>
                                    </div>
                                  </DataDisplay>
                                </li>
                              </div>
                              <li>
                                <DataDisplay label="Port Beschreibung">
                                  <div v-if="
                                      contractData[contractId]
                                        .provisioningData &&
                                        contractData[contractId].provisioningData
                                          .msanProvisioning &&
                                        contractData[contractId].provisioningData
                                          .msanProvisioning
                                          .provisioningInformation
                                    ">
                                    <div>
                                      {{
                                        contractData[contractId]
                                          .provisioningData.msanProvisioning
                                          .provisioningInformation
                                          .portDescription
                                      }}
                                    </div>
                                  </div>
                                </DataDisplay>
                              </li>
                              <li>
                                <DataDisplay label="Konfigurierte ONT Seriennummer">
                                  <div v-if="
                                      contractData[contractId]
                                        .provisioningData &&
                                        contractData[contractId].provisioningData
                                          .msanProvisioning &&
                                        contractData[contractId].provisioningData
                                          .msanProvisioning
                                          .provisioningInformation
                                    ">
                                    <div>
                                      {{
                                        contractData[contractId]
                                          .provisioningData.msanProvisioning
                                          .provisioningInformation
                                          .configuredOntSerial
                                      }}
                                    </div>
                                  </div>
                                </DataDisplay>
                              </li>
                            </ul>
                          </td>
                          <td class="top">
                            <ul>
                              <li>
                                <DataDisplay label="Radius User">
                                  <div v-if="
                                      contractData[contractId]
                                        .provisioningData &&
                                        contractData[contractId].provisioningData
                                          .internetProvisioning
                                          .provisioningInformation.userName
                                    ">
                                    <div>
                                      {{
                                        contractData[contractId]
                                          .provisioningData.internetProvisioning
                                          .provisioningInformation.userName
                                      }}
                                    </div>
                                  </div>
                                </DataDisplay>
                              </li>
                              <li>
                                <DataDisplay label="Letzte Session">
                                  <div v-if="
                                      contractData[contractId]
                                        .provisioningData &&
                                        contractData[contractId].provisioningData
                                          .internetProvisioning
                                          .provisioningInformation.lastSession
                                    ">
                                    <v-tooltip bottom>
                                      <template v-slot:activator="{ on, attrs }">
                                        <v-icon
                                          v-if="
                                            contractData[contractId]
                                              .provisioningData
                                              .internetProvisioning
                                              .provisioningInformation
                                              .lastSession.acctStartTime &&
                                              contractData[contractId]
                                                .provisioningData
                                                .internetProvisioning
                                                .provisioningInformation
                                                .lastSession.acctStopTime
                                          "
                                          color="red"
                                          dark
                                          v-bind="attrs"
                                          v-on="on"
                                        >
                                          mdi-lightning-bolt-circle
                                        </v-icon>
                                        <v-icon
                                          v-if="
                                            contractData[contractId]
                                              .provisioningData
                                              .internetProvisioning
                                              .provisioningInformation
                                              .lastSession.acctStartTime &&
                                              !contractData[contractId]
                                                .provisioningData
                                                .internetProvisioning
                                                .provisioningInformation
                                                .lastSession.acctStopTime
                                          "
                                          color="green"
                                          dark
                                          v-bind="attrs"
                                          v-on="on"
                                        >
                                          mdi-lightning-bolt-circle
                                        </v-icon>
                                        <span
                                          v-if="
                                            contractData[contractId]
                                              .provisioningData
                                              .internetProvisioning
                                              .provisioningInformation
                                              .lastSession.acctStopTime
                                          "
                                          v-bind="attrs"
                                          v-on="on"
                                        >
                                          Offline</span>
                                        <span
                                          v-else
                                          v-bind="attrs"
                                          v-on="on"
                                        >Online</span>
                                      </template>
                                      <span v-if="
                                          contractData[contractId]
                                            .provisioningData
                                            .internetProvisioning
                                            .provisioningInformation.lastSession
                                            .acctStartTime
                                        ">Session Start:
                                        {{
                                          formatDate(
                                            contractData[contractId]
                                              .provisioningData
                                              .internetProvisioning
                                              .provisioningInformation
                                              .lastSession.acctStartTime
                                          )
                                        }}
                                      </span>
                                      <br />
                                      <span v-if="
                                          contractData[contractId]
                                            .provisioningData
                                            .internetProvisioning
                                            .provisioningInformation.lastSession
                                            .acctStopTime
                                        ">Session Ende:
                                        {{
                                          formatDate(
                                            contractData[contractId]
                                              .provisioningData
                                              .internetProvisioning
                                              .provisioningInformation
                                              .lastSession.acctStopTime
                                          )
                                        }}</span>
                                    </v-tooltip>
                                  </div>
                                </DataDisplay>
                              </li>

                              <li>
                                <DataDisplay label="Mac-Adresse">
                                  <div v-if="
                                      contractData[contractId]
                                        .provisioningData &&
                                        contractData[contractId].provisioningData
                                          .internetProvisioning
                                          .provisioningInformation.accounting &&
                                        contractData[contractId].provisioningData
                                          .internetProvisioning
                                          .provisioningInformation.accounting
                                          .macAddress
                                    ">
                                    <div>
                                      {{
                                        contractData[contractId]
                                          .provisioningData.internetProvisioning
                                          .provisioningInformation.accounting
                                          .macAddress
                                      }}
                                    </div>
                                  </div>
                                </DataDisplay>
                              </li>
                              <li>
                                <DataDisplay label="IPv4">
                                  <div v-if="
                                      contractData[contractId]
                                        .provisioningData &&
                                        contractData[contractId].provisioningData
                                          .internetProvisioning
                                          .provisioningInformation.accounting &&
                                        contractData[contractId].provisioningData
                                          .internetProvisioning
                                          .provisioningInformation.accounting
                                          .ipv4Address
                                    ">
                                    <div>
                                      {{
                                        contractData[contractId]
                                          .provisioningData.internetProvisioning
                                          .provisioningInformation.accounting
                                          .ipv4Address
                                      }}
                                    </div>
                                  </div>
                                </DataDisplay>
                              </li>
                              <li>
                                <DataDisplay label="IPv6-Prefix">
                                  <div v-if="
                                      contractData[contractId]
                                        .provisioningData &&
                                        contractData[contractId].provisioningData
                                          .internetProvisioning
                                          .provisioningInformation.accounting &&
                                        contractData[contractId].provisioningData
                                          .internetProvisioning
                                          .provisioningInformation.accounting
                                          .ipv6Address
                                    ">
                                    <div>
                                      {{
                                        contractData[contractId]
                                          .provisioningData.internetProvisioning
                                          .provisioningInformation.accounting
                                          .ipv6Address
                                      }}
                                    </div>
                                  </div>
                                </DataDisplay>
                              </li>
                              <li>
                                <DataDisplay label="Bandbreite">
                                  <div v-if="
                                      contractData[contractId]
                                        .provisioningData &&
                                        contractData[contractId].provisioningData
                                          .internetProvisioning
                                          .provisioningInformation.bandwidth
                                    ">
                                    <v-tooltip bottom>
                                      <template v-slot:activator="{ on, attrs }">
                                        <v-icon
                                          color="green"
                                          dark
                                          v-bind="attrs"
                                          v-on="on"
                                        >
                                          mdi-download
                                        </v-icon>
                                      </template>
                                      <span v-if="
                                          contractData[contractId]
                                            .provisioningData
                                            .internetProvisioning
                                            .provisioningInformation.bandwidth
                                            .download
                                        ">Download
                                      </span>
                                    </v-tooltip>
                                    <span>{{
                                      contractData[contractId].provisioningData
                                        .internetProvisioning
                                        .provisioningInformation.bandwidth
                                        .download
                                    }}</span>
                                    &nbsp; &nbsp;
                                    <v-tooltip bottom>
                                      <template v-slot:activator="{ on, attrs }">
                                        <v-icon
                                          color="orange"
                                          dark
                                          v-bind="attrs"
                                          v-on="on"
                                        >
                                          mdi-upload
                                        </v-icon>
                                      </template>
                                      <span v-if="
                                          contractData[contractId]
                                            .provisioningData
                                            .internetProvisioning
                                            .provisioningInformation.bandwidth
                                            .upload
                                        ">Upload
                                      </span>
                                    </v-tooltip>
                                    <span>{{
                                      contractData[contractId].provisioningData
                                        .internetProvisioning
                                        .provisioningInformation.bandwidth
                                        .upload
                                    }}</span>
                                  </div>
                                </DataDisplay>
                              </li>
                            </ul>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </div>
                <div v-else-if="contractData[contractId].provisioningData === null">
                  <span>Es sind keine Einträge vorhanden.</span>
                </div>
                <div
                  v-else
                  class="text-center"
                >
                  <v-progress-circular
                    :width="3"
                    indeterminate
                    color="primary"
                  ></v-progress-circular>
                </div>
              </v-tab-item>
              <v-tab-item>
                <v-data-table
                  :key="contractId"
                  :headers="[
                    {
                      text: 'Telefonnummer',
                      align: 'start',
                      value: 'phoneNumber'
                    },
                    {
                      text: 'Provisioniert',
                      value: 'provisioningInformation.isAlreadyProvisioned'
                    },
                    {
                      text: 'Softswitch',
                      value: 'provisioningInformation.softSwitchType'
                    },
                    {
                      text: 'Gültigkeitsdatum der VoIP Session',
                      value:
                        'provisioningInformation.expiryDateCurrentVoIpSession'
                    },
                    {
                      text: 'ACS Status',
                      value: 'provisioningInformation.acsStatus'
                    }
                  ]"
                  :items="
                    contractData[contractId].provisioningData &&
                      contractData[contractId].provisioningData
                        .telephoneProvisioning
                      ? contractData[contractId].provisioningData
                        .telephoneProvisioning
                        .phoneNumbersAvailableForProvisioning
                      : []
                  "
                  item-key="phoneNumber"
                  dense
                  hide-default-footer
                  class="elevation-1 margin-top-1"
                >
                  <template v-slot:[`item.provisioningInformation.isAlreadyProvisioned`]="{
                      item
                    }">
                    <v-icon
                      v-if="item.provisioningInformation.isAlreadyProvisioned"
                      color="#6aa84f"
                    >
                      mdi-check-circle
                    </v-icon>
                    <v-icon
                      v-if="!item.provisioningInformation.isAlreadyProvisioned"
                      color="#9b3232"
                    >
                      mdi-close-circle
                    </v-icon>
                  </template>
                </v-data-table>
              </v-tab-item>
              <v-tab-item>
                <template v-if="contractData[contractId].projectDetails === null">
                  <span v-if="contractData[contractId].addressShortDto.objectInfo === null">Für diesen Vertrag können keine Projekt Details abgerufen werden.</span>
                  <v-btn
                    v-else
                    :loading="contractData[contractId].projectDetailsLoading"
                    @click="loadProjectDetails(contractId)"
                  >
                    <v-icon left>
                      mdi-download
                    </v-icon>
                    Projekt Details laden
                  </v-btn>
                </template>
                <template v-else>
                  <div class="input-row">
                    <DataDisplay label="Projekt Informationen">
                      <template v-if="contractData[contractId].projectDetails.projectInformation">
                        <v-simple-table class="mr-10">
                          <template v-slot:default>
                            <tbody>
                              <tr>
                                <td>Projekt-ID</td>
                                <td>{{ contractData[contractId].projectDetails.projectInformation.projektId }}</td>
                              </tr>
                              <tr>
                                <td>Name</td>
                                <td>{{ contractData[contractId].projectDetails.projectInformation.name }}</td>
                              </tr>
                              <tr>
                                <td>Ort</td>
                                <td>{{ contractData[contractId].projectDetails.projectInformation.city }}</td>
                              </tr>
                              <tr>
                                <td>Ortsteil</td>
                                <td>{{ contractData[contractId].projectDetails.projectInformation.district }}</td>
                              </tr>
                              <tr>
                                <td>Vertriebsphase</td>
                                <td :style="{ color: contractData[contractId].projectDetails.projectInformation.distributionStatus.color }">
                                  <b>{{ contractData[contractId].projectDetails.projectInformation.distributionStatus.description }}</b>
                                </td>
                              </tr>
                              <tr>
                                <td>Projektstatus</td>
                                <td :style="{ color: contractData[contractId].projectDetails.projectInformation.projectStatus.color }">
                                  <b>{{ contractData[contractId].projectDetails.projectInformation.projectStatus.description }}</b>
                                </td>
                              </tr>
                              <tr>
                                <td>Baustart</td>
                                <td>
                                  {{
                                    contractData[contractId].projectDetails.projectInformation.startDate
                                      ? toHumanDate(contractData[contractId].projectDetails.projectInformation.startDate)
                                      : "nicht gesetzt"
                                  }}
                                </td>
                              </tr>
                            </tbody>
                          </template>
                        </v-simple-table>
                      </template>
                      <template v-else>
                        <span>Keine Projektinformationen verfügbar</span>
                      </template>
                    </DataDisplay>
                    <DataDisplay label="Liegenschaftsinformationen">
                      <template v-if="contractData[contractId].projectDetails.propertyState !== null">
                        <div class="my-4">
                          <!-- House Connection Built -->
                          <v-icon :color="contractData[contractId].projectDetails.propertyState.houseConnectionBuiltDate ? '#6aa84f' : '#9b3232'">
                            mdi-home-alert
                          </v-icon>
                          <span v-if="contractData[contractId].projectDetails.propertyState.houseConnectionBuiltDate">
                            Hausanschluss gebaut gesetzt am {{ contractData[contractId].projectDetails.propertyState.houseConnectionBuiltDate }}
                          </span>
                          <span v-else>Hausanschluss nicht gebaut</span>
                        </div>
                        <!-- House Connection Patched -->
                        <div class="my-4">
                          <v-icon :color="contractData[contractId].projectDetails.propertyState.houseConnectionPatchedDate ? '#6aa84f' : '#9b3232'">
                            mdi-home-assistant
                          </v-icon>
                          <span v-if="contractData[contractId].projectDetails.propertyState.houseConnectionPatchedDate">
                            Haus gepatched gesetzt am {{ contractData[contractId].projectDetails.propertyState.houseConnectionPatchedDate }}
                          </span>
                          <span v-else>Haus nicht gepatched</span>
                        </div>
                        <!-- PoP online -->
                        <div class="my-4">
                          <v-icon :color="contractData[contractId].projectDetails.propertyState.popOnlineDate ? '#6aa84f' : '#9b3232'">
                            mdi-transit-connection-variant
                          </v-icon>
                          <span v-if="contractData[contractId].projectDetails.propertyState.popOnlineDate">
                            POP Online gesetzt am {{ contractData[contractId].projectDetails.propertyState.popOnlineDate }}
                          </span>
                          <span v-else>POP nicht Online</span>
                        </div>
                      </template>
                      <template v-else>
                        <div class="my-4">
                          <!-- House Connection Built -->
                          <v-icon color="#9b3232">
                            mdi-home-alert
                          </v-icon>
                          <span>Hausanschluss nicht gebaut</span>
                        </div>
                        <!-- House Connection Patched -->
                        <div class="my-4">
                          <v-icon color="#9b3232">
                            mdi-home-assistant
                          </v-icon>
                          <span>Haus nicht gepatched</span>
                        </div>
                        <!-- PoP online -->
                        <div class="my-4">
                          <v-icon color="#9b3232">
                            mdi-transit-connection-variant
                          </v-icon>
                          <span>POP nicht Online</span>
                        </div>
                      </template>
                    </DataDisplay>
                  </div>
                </template>
              </v-tab-item>
            </v-tabs-items>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </template>
  </DisplayContainer>
</template>

<script>
import Tools from '../../main/tools';
import { HTTP } from '@/main/httpClient.js';
import TimeUtility from '@/util/TimeUtility.js';
import NotificationObject from '@/main/NotificationObject.js';

import DataDisplay from '@/components/elements/DataDisplay.vue';
import NameDisplay from '@/components/dataDisplays/NameDisplay.vue';
import AddressDisplay from '@/components/dataDisplays/AddressDisplay.vue';
import DisplayContainer from '@/components/elements/DisplayContainer.vue';
import ContactInformation from '@/components/ContactInformation.vue';
import DateDisplay from '@/components/dataDisplays/DateDisplay.vue';
import AllOrderedProductsTable from '@/components/AllOrderedProductsTable.vue';
import MsanProvisioningInformation from '@/components/deviceProvisioning/MsanProvisioningInformation.vue';

export default {
  name: 'DisplayContractData',
  components: {
    DisplayContainer,
    DataDisplay,
    DateDisplay,
    NameDisplay,
    AddressDisplay,
    ContactInformation,
    AllOrderedProductsTable,
    MsanProvisioningInformation
  },
  props: {
    contractData: { type: Object, required: true },
    provisioningDataLoading: { type: Boolean, required: true },
    processes: { type: Array, default: undefined }
  },
  data: () => ({
    tab: null,
    tabItems: [
      'Details',
      'Produkte',
      'SLA Information',
      'INTERNET',
      'TELEFONIE',
      'Projekt Details',
    ],
    phaseInRunning: null,
  }),
  computed: {
    phaseInPossibleLoading: function () {
      return !this.processes;
    },
    phaseInPermission: function () {
      const tool = Tools.find((t) => t.id === 'contract-phase-in');
      const groups = tool?.auth ?? ['camunda-admin'];
      return this.$auth.check(groups);
    }
  },
  watch: {
    contractData: {
      handler: function () {
        for (const contractId of Object.keys(this.contractData)) {
          const contract = this.contractData[contractId];
          const process = this.processes?.filter(
            (process) => parseInt(process.contractId) === parseInt(contractId)
          );
          contract.phaseInPossible =
            !(process?.length > 0) &&
            !contract.deactiveDate &&
            !contract.cancellationDate &&
            !contract.activeDate &&
            contract.internetMainProduct &&
            !contract.internetMainProduct.dateOfActivation &&
            !contract.internetMainProduct.dateOfDeactivation;
          contract.addressShortDto.objectInfo =
            contract.addressShortDto.objectInfos &&
            contract.addressShortDto.objectInfos.length > 0
              ? contract.addressShortDto.objectInfos[0]
              : null;
          contract.projectDetails = null;
          contract.projectDetailsLoading = false;
        }
      },
      deep: true,
    },
    processes: {
      handler: function (processes) {
        for (const process of processes) {
          const contract = this.contractData[process.contractId];
          if (!contract) return;
          contract.phaseInPossible = false;
        }
      }
    },
    deep: true,
  },
  methods: {
    getProvisionedMessage(val) {
      if (val === true) {
        return 'Provisioniert';
      }
      return 'nicht Provisioniert';
    },
    phaseInContract(contractId) {
      if (this.phaseInRunning !== null) return;
      this.phaseInRunning = parseInt(contractId);
      this.contractData[contractId].phaseInRunning = true;
      HTTP.post('order/startLegacy', [parseInt(contractId)])
        .then((res) => {
          if (!res.status === 200) {
            this.showToast(
              `Einphasen von Vertrag ${this.phaseInRunning} war nicht erfolgreich: ${res.status}`
            );
            return;
          }

          const imported = Object.hasOwnProperty.call(res.data, this.phaseInRunning);

          if (!imported) {
            this.showToast([
              `Einphasen von Vertrag ${this.phaseInRunning} war nicht erfolgreich, mögliche Gründe`,
              'An dem Vertrag ist keine passende Installationsadresse vorhanden',
              'Vertrag existiert nicht',
              'Vertrag ist bereits aktiv oder wurde deaktiviert'
            ], 'warning');
            return;
          }

          const result = res.data[this.phaseInRunning];

          if (!result.success) {
            this.showToast(`Einphasen von Vertrag ${this.phaseInRunning} war nicht erfolgreich: ${result.errorMessage}`, 'warning');
            return;
          }

          this.showToast(`Einphasen von Vertrag ${this.phaseInRunning} war erfolgreich`, 'success');
          this.contractData[contractId].phaseInPossible = false;
          this.$emit('requestProcessUpdate');
        })
        .catch((err) => {
          this.showToast(`Fehler einphasen von Vertrag ${this.phaseInRunning} - ${err}`);
        })
        .finally(() => {
          this.phaseInRunning = null;
        });
    },
    formatDate(date) {
      if (date) return TimeUtility.formatLocalDate(date);
      return null;
    },
    formatDateTime(date) {
      if (date) return TimeUtility.formatDateTimeWithoutUhr(date);
      return null;
    },
    phaseInPossible: function (contractId) {
      return this.contractData[contractId].phaseInPossible;
    },
    loadProjectDetails: async function (contractId) {
      if (
        this.contractData[contractId].projectDetails ||
        !this.contractData[contractId] ||
        !this.contractData[contractId].addressShortDto) return;
      const contract = this.contractData[contractId];
      contract.projectDetailsLoading = true;
      this.tab = 0;
      this.tab = this.tabItems.length - 1;

      const { ags27: ags, projectId } = contract.addressShortDto.objectInfo;
      HTTP.get(`/productAvailabilityTool/details?ags=${ags}&projectId=${projectId}`)
        .then(({ status, data }) => {
          if (status !== 200) {
            this.showToast('Error beim laden der Projekt Informationen');
            return;
          }
          if (data && data.propertyState) {
            data.propertyState.houseConnectionBuiltDate = this.toHumanDate(data.propertyState.houseConnectionBuiltDate);
            data.propertyState.houseConnectionPatchedDate = this.toHumanDate(data.propertyState.houseConnectionPatchedDate);
            data.propertyState.popOnlineDate = this.toHumanDate(data.propertyState.popOnlineDate);
          }
          contract.projectDetails = data;
        })
        .catch(() => {
          this.showToast('Error beim laden der Projekt Informationen.');
        })
        .finally(() => {
          contract.projectDetailsLoading = false;
          this.tab = 0;
          this.tab = this.tabItems.length - 1;
        })
    },
    toHumanDate: function (date) {
      if (!date) return null;
      const d = new Date(Date.parse(date));
      return `${d.getDate().toString().padStart(2, '0')}.${(d.getMonth() + 1).toString().padStart(2, '0')}.${d.getFullYear()}`;
    },
    showToast: function (message, type = 'error') {
      this.$store.commit(
        'addNotification',
        new NotificationObject(type, message)
      );
    },
  }
};
</script>

<style scoped>
.top {
  width: 259px;
  vertical-align: top;
}
.divTabItems {
  height: 400px;
}
.sameLine {
  display: flex;
  align-items: flex-start;
}
.space {
  margin: 10px;
}
.center {
  text-align: center;
}
.status-white {
  width: 21px;
  height: 21px;
  background-color: #ffffff;
  border-radius: 50%;
  box-shadow: 0 0 4px 1px #646464;
}
.fontBold {
  font-weight: bold;
}
.status-green {
  width: 21px;
  height: 21px;
  background-color: #80ed99;
  border-radius: 50%;
  box-shadow: 0 0 4px 1px #80ed99;
}
.status-yellow {
  width: 21px;
  height: 21px;
  background-color: #d2e622;
  border-radius: 50%;
  box-shadow: 0 0 4px 1px #d2e622;
}
.status-red {
  width: 21px;
  height: 21px;
  background-color: #ff6b6b;
  border-radius: 50%;
  box-shadow: 0 0 4px 1px #ff6b6b;
}
.pase-in-icon {
  margin-left: auto;
  display: inline-flex;
  margin-bottom: -4px;
  margin-top: -4px;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}
</style>
