<!--
Kundenname
Kundennummer/System (VarioCRM, ACOS, Commence)
Adresse (Installationsadresse & Rechnungsadresse)
Geburtsdatum
E-Mail Adresse
Accounting information

SEPA/IBAN information
or "on account" if DB-Field TBD is TBD

Customer password, if available
-->

<template>
  <div>
    <DisplayContainer label="Kundendetails">
      <div class="input-row">
        <DataDisplay label="Name/Firma">
          <div>
            {{ metaData.customerName }}
          </div>
        </DataDisplay>
        <DataDisplay label="Kundennummer">
          <div v-if="metaData.customerId">
            {{ metaData.customerId }}
          </div>
        </DataDisplay>
      </div>
      <div class="input-row">
        <DataDisplay label="Geburtsdatum">
          <div>
            {{ metaData.birthDate }}
          </div>
        </DataDisplay>
        <DataDisplay label="E-Mail">
          <div v-if="metaData.email">
            {{ metaData.email }}
          </div>
        </DataDisplay>
      </div>

      <div class="input-row">
        <DataDisplay label="SEPA">
          <ul v-if="metaData.accounts.length > 0">
            <li
              v-for="(account, indx) in metaData.accounts"
              :key="indx"
            >
              {{ account }}
            </li>
          </ul>
          <span v-else>-</span>
        </DataDisplay>
        <DataDisplay label="Kundenpasswort">
          <div>
            {{ metaData.customerPassword }}
          </div>
        </DataDisplay>
      </div>
    </DisplayContainer>
    <DisplayContainer label="Adresse">
      <v-data-table
        :headers="[
          {
            text: 'Adresstyp',
            align: 'start',
            value: 'addressType'
          },
          {
            text: 'Name/Firma',
            value: 'nameFirma'
          },
          {
            text: 'Adresse',
            value: 'compactAddress'
          }
        ]"
        :items="metaData.addresses"
        item-key="Adresse"
        dense
        hide-default-footer
        class="elevation-1 margin-top-1"
      >
        <template v-slot:[`item.addressType`]="{ item }">
          <v-icon
            v-if="item.addressType === 'Rechnungsadresse'"
            color="blue"
          >
            mdi-home-account
          </v-icon>
          <v-icon
            v-if="item.addressType === 'Installationsadresse'"
            color="blue"
          >
            mdi-home-analytics
          </v-icon>
          <v-icon
            v-if="item.addressType === 'Telefonbuchadresse'"
            color="blue"
          >
            mdi-phone
          </v-icon>
          <v-icon
            v-if="item.addressType === 'Kontaktadresse'"
            color="blue"
          >
            mdi-contacts
          </v-icon>
          <v-icon
            v-if="item.addressType === 'Versandadresse'"
            color="blue"
          >
            mdi-map-marker-path
          </v-icon>
          <v-icon
            v-if="item.addressType === 'Hausbesitzeradresse'"
            color="blue"
          >
            mdi-home
          </v-icon>
          {{ item.addressType }}
        </template>
        <template v-slot:[`item.compactAddress`]="{ item }">
          <v-icon color="green">
            mdi-map-marker
          </v-icon>
          {{ item.compactAddress }}
        </template>
      </v-data-table>
    </DisplayContainer>
    <DisplayContainer
      v-if="crmSystem == 'VarioCRM'"
      label="Aktionen"
    >
      <div class="text">
        <template>
          <v-dialog
            v-model="dialog"
            max-width="500px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="green"
                class="ma-2 white--text"
                :loading="loadingData"
                :disabled="
                  !contractIdsProvisionedPhoneNumbers ||
                    contractIdsProvisionedPhoneNumbers.length === 0
                "
                v-bind="attrs"
                v-on="on"
              >
                Zugangsdaten
                <v-icon
                  dark
                  right
                >
                  mdi-send
                </v-icon>
              </v-btn>
            </template>
            <template>
              <v-card>
                <v-card-title>
                  <span class="text-h5">Telefonzugangsdaten Generierung</span>
                </v-card-title>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col
                        cols="12"
                        sm="10"
                        md="8"
                      >
                        <p class="thick">
                          Vertrag *
                        </p>
                        <v-radio-group
                          v-model="contractSelection"
                          mandatory
                        >
                          <v-radio
                            v-for="n in contractIdsProvisionedPhoneNumbers"
                            :key="n"
                            :label="` ${n}`"
                            :value="n"
                          >
                          </v-radio>
                        </v-radio-group>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col
                        cols="12"
                        sm="8"
                        md="6"
                      >
                        <p class="thick">
                          Versand
                        </p>
                        <v-radio-group v-model="versandSelection">
                          <v-radio
                            v-for="n in versand"
                            :key="n"
                            :label="` ${n}`"
                            :value="n"
                          >
                          </v-radio>
                        </v-radio-group>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="red"
                    text
                    @click="close"
                  >
                    <v-icon
                      dark
                      right
                    >
                      mdi-cancel
                    </v-icon>
                    Abbrechen
                  </v-btn>
                  <v-btn
                    color="green"
                    text
                    @click="save"
                  >
                    <v-icon
                      dark
                      right
                    >
                      mdi-check
                    </v-icon>
                    {{ versandSelection === 'kein Versand'
                      ? 'Dokument Generieren'
                      : 'Dokument Versenden', }}
                  </v-btn>
                </v-card-actions>
              </v-card>
              <v-overlay :value="loading">
                <v-progress-circular
                  :size="120"
                  color="primary"
                  indeterminate
                ></v-progress-circular>
              </v-overlay>
            </template>
          </v-dialog>
        </template>

        <template>
          <v-dialog
            v-model="serviceOrderDocDialog"
            max-width="500px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="green"
                class="ma-2 white--text"
                :loading="loadingData"
                :disabled="
                  !contractIdsProvisionedPhoneNumbers ||
                    contractIdsProvisionedPhoneNumbers.length === 0
                "
                v-bind="attrs"
                v-on="on"
              >
                Auftragsbestätigung
                <v-icon
                  dark
                  right
                >
                  mdi-send
                </v-icon>
              </v-btn>
            </template>
            <template>
              <v-card>
                <v-card-title>
                  <span class="text-h5">Auftragsbestätigung Generierung</span>
                </v-card-title>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col
                        cols="12"
                        sm="10"
                        md="8"
                      >
                        <p class="thick">
                          Vertrag *
                        </p>
                        <v-radio-group
                          v-model="contractSelection"
                          mandatory
                        >
                          <v-radio
                            v-for="n in contractIdsProvisionedPhoneNumbers"
                            :key="n"
                            :label="` ${n}`"
                            :value="n"
                          >
                          </v-radio>
                        </v-radio-group>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col
                        cols="12"
                        sm="8"
                        md="6"
                      >
                        <p class="thick">
                          Versand
                        </p>
                        <v-radio-group v-model="versandSelection">
                          <v-radio
                            v-for="n in versandNeutral"
                            :key="n"
                            :label="` ${n}`"
                            :value="n"
                          >
                          </v-radio>
                        </v-radio-group>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="red"
                    text
                    @click="closeServiceOrderDoc"
                  >
                    <v-icon
                      dark
                      right
                    >
                      mdi-cancel
                    </v-icon>
                    Abbrechen
                  </v-btn>
                  <v-btn
                    color="green"
                    text
                    @click="createServiceOrderConfirmationDocuments"
                  >
                    <v-icon
                      dark
                      right
                    >
                      mdi-check
                    </v-icon>
                    {{ versandSelection === 'kein Versand'
                      ? 'Dokumente Generieren'
                      : 'Dokumente Versenden', }}
                  </v-btn>
                </v-card-actions>
              </v-card>
              <v-overlay :value="loading">
                <v-progress-circular
                  :size="120"
                  color="primary"
                  indeterminate
                ></v-progress-circular>
              </v-overlay>
            </template>
          </v-dialog>
        </template>

        <v-dialog
          v-model="tvDialog"
          max-width="800px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-if="
                userGroups.includes('LgSpotAuftragserfassung') ||
                  userGroups.includes('camunda-admin')
              "
              class="ma-2 white--text"
              color="purple darken-2"
              v-bind="attrs"
              :loading="productsDataLoading || loadingData"
              :disabled="!activeProducts || activeProducts.size === 0"
              v-on="on"
            >
              Wechsel TV-Tarif
              <v-icon
                dark
                right
              >
                mdi-television-classic
              </v-icon>
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="text-h5">TV-Tarif Änderung</span>
            </v-card-title>
            <v-card-text>
              <v-tabs
                v-model="tab"
                fixed-tabs
                slider-size="5"
                slider-color="#E1BEE7"
                background-color="#7B1FA2"
                dark
              >
                <v-tab
                  v-for="tabItem in tabItems"
                  :key="tabItem"
                  @change="tabChangeValue(tabItem)"
                >
                  {{ tabItem }}
                </v-tab>
              </v-tabs>
              <v-tabs-items v-model="tab">
                <v-tab-item>
                  <v-container>
                    <div>
                      <v-row>
                        <v-col
                          cols="10"
                          sm="8"
                          md="7"
                        >
                          <p class="bold">
                            Vertrag *
                          </p>
                          <v-radio-group
                            v-model="tvVertragSelectionDefault"
                            mandatory
                          >
                            <v-chip color="#FCE4EC">
                              <v-radio
                                v-for="contract in tvContractsWithPrices"
                                :key="contract.id"
                                :label="` ${contract.contractId} - ${
                                  contract.contractName
                                } - ${
                                  contract.activeProduct
                                } / ${contract.activeProductPrice
                                  .toString()
                                  .replace('.', ',')}€`"
                                :value="contract"
                              >
                              </v-radio>
                            </v-chip>
                          </v-radio-group>
                          <v-chip
                            v-for="waipu in metaData.waipuTvList"
                            :key="waipu.id"
                            color="#FCE4EC"
                          >
                            Aktivierung: {{ waipu.activatedAt }} Gesperrt:
                            {{ waipu.locked ? 'Ja' : 'Nein' }}
                          </v-chip>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col
                          cols="10"
                          sm="8"
                          md="7"
                        >
                          <v-combobox
                            v-model="select"
                            :items="filteredTvProductList"
                            :item-text="
                              (item) => `${item.name} / ${item.price}€`
                            "
                            class="bold"
                            label="Wechsel auf Tarif"
                            outlined
                            dense
                            @change="onChangeTarif()"
                          ></v-combobox>
                        </v-col>
                        <v-col>
                          <v-chip
                            class="ma-2"
                            color="#26C6DA"
                            outlined
                          >
                            <v-icon left>
                              {{
                                newTarif === 'Downgrade'
                                  ? 'mdi-trending-down'
                                  : 'mdi-trending-up'
                              }}
                            </v-icon>
                            {{ newTarif }}
                          </v-chip>
                        </v-col>
                      </v-row>
                      <div>
                        <v-row>
                          <v-col
                            cols="10"
                            sm="8"
                            md="7"
                          >
                            <DatePicker
                              v-model="datePicker"
                              class="mt-4"
                              color="#7B1FA2"
                              label="Änderungsdatum"
                              outlined
                              :minDate="minDate"
                            ></DatePicker>
                          </v-col>
                          <v-col>
                            <v-chip
                              v-if="
                                newTarif === 'Downgrade' &&
                                  datePicker < minDatePicker
                              "
                              class="padding"
                              color="#F2AF29"
                              outlined
                            >
                              <v-icon left>
                                mdi-alert
                              </v-icon>
                              Tarif-Downgrade sollten <br />in der Regel 30 Tage
                              <br />
                              im Voraus angegeben werden.
                            </v-chip>
                          </v-col>
                        </v-row>
                      </div>
                    </div>
                  </v-container>
                </v-tab-item>
                <v-tab-item>
                  <v-container>
                    <v-row>
                      <v-col
                        cols="10"
                        sm="8"
                        md="7"
                      >
                        <p class="bold">
                          Vertrag *
                        </p>
                        <v-radio-group
                          v-model="tvVertragSelectionDefault"
                          mandatory
                        >
                          <v-chip color="#FCE4EC">
                            <v-radio
                              v-for="contract in tvContractsWithPrices"
                              :key="contract.id"
                              :label="` ${contract.contractId} - ${
                                contract.contractName
                              } - ${
                                contract.activeProduct
                              } / ${contract.activeProductPrice
                                .toString()
                                .replace('.', ',')}€`"
                              :value="contract"
                            >
                            </v-radio>
                          </v-chip>
                        </v-radio-group>
                        <v-chip
                          v-for="waipu in metaData.waipuTvList"
                          :key="waipu.id"
                          color="#FCE4EC"
                        >
                          Aktivierung: {{ waipu.activatedAt }} Gesperrt:
                          {{ waipu.locked ? 'Ja' : 'Nein' }}
                        </v-chip>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col
                        cols="10"
                        sm="8"
                        md="7"
                      >
                        <DatePicker
                          v-model="datePickerCancel"
                          color="#7B1FA2"
                          label="Kündigungsdatum"
                          class="mt-4"
                          outlined
                          :minDate="minDate"
                        ></DatePicker>
                      </v-col>

                      <v-chip
                        v-if="datePickerCancel < minDatePicker"
                        color="#F2AF29"
                        outlined
                        class="padding"
                      >
                        <v-icon left>
                          mdi-alert
                        </v-icon>
                        Kündigungen sollten <br />
                        in der Regel 30 Tage<br />
                        im Voraus angeben werden.
                      </v-chip>
                    </v-row>
                  </v-container>
                </v-tab-item>
                <v-tab-item>
                  <v-container>
                    <v-row>
                      <v-col
                        cols="10"
                        sm="8"
                        md="7"
                      >
                        <p class="bold">
                          Vertrag *
                        </p>
                        <v-radio-group
                          v-model="tvVertragSelectionDefault"
                          mandatory
                        >
                          <v-chip color="#FCE4EC">
                            <v-radio
                              v-for="contract in tvContractsWithPrices"
                              :key="contract.id"
                              :label="` ${contract.contractId} - ${
                                contract.contractName
                              } - ${
                                contract.activeProduct
                              } / ${contract.activeProductPrice
                                .toString()
                                .replace('.', ',')}€`"
                              :value="contract"
                            >
                            </v-radio>
                          </v-chip>
                        </v-radio-group>
                      </v-col>
                      <v-col
                        cols="10"
                        sm="8"
                        md="7"
                      >
                        <v-chip
                          v-for="waipu in metaData.waipuTvList"
                          :key="waipu.id"
                          color="#FCE4EC"
                        >
                          Aktivierung: {{ waipu.activatedAt }} Gesperrt:
                          {{ waipu.locked ? 'Ja' : 'Nein' }}
                        </v-chip>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-tab-item>
              </v-tabs-items>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="red"
                text
                @click="tvClose"
              >
                <v-icon
                  dark
                  right
                >
                  mdi-cancel
                </v-icon>
                Abbrechen
              </v-btn>
              <v-dialog
                v-model="cancelDialog"
                max-width="500px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="green"
                    text
                    v-bind="attrs"
                    class="ma-2"
                    v-on="on"
                  >
                    <v-icon
                      dark
                      right
                    >
                      {{
                        tvTabSelection === 'Tarif Wechsel'
                          ? 'mdi-file-edit-outline'
                          : 'mdi-check'
                      }}
                    </v-icon>
                    {{
                      tvTabSelection === 'Tarif Wechsel'
                        ? 'TV Tarif ändern'
                        : tvTabSelection === 'Tarif Kündigen'
                          ? 'TV Tarif Kündigung'
                          : 'Tarif ' + lockUnlockText
                    }}
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title class="text-h5">
                    {{
                      tvTabSelection === 'Tarif Wechsel'
                        ? 'TV Vertrag ändern?'
                        : tvTabSelection === 'Tarif Kündigen'
                          ? 'TV Tarif Kündigung?'
                          : 'TV Tarif ' + lockUnlockText
                    }}
                  </v-card-title>
                  <v-card-text>
                    {{
                      tvTabSelection === 'Tarif Wechsel'
                        ? 'Sind Sie sicher, dass Sie den TV Vertrag ändern wollen?'
                        : tvTabSelection === 'Tarif Kündigen'
                          ? 'Sind Sie sicher, dass Sie den TV Vertrag kündigen wollen?'
                          : 'Sind Sie sicher, dass Sie den TV Vertrag ' +
                            lockUnlockText +
                            ' wollen?'
                    }}
                  </v-card-text>
                  <v-card-actions class="cancel-dialog">
                    <v-btn
                      color="green darken-1"
                      text
                      @click="tvSave"
                    >
                      <v-icon
                        dark
                        right
                      >
                        mdi-check
                      </v-icon>
                      {{
                        tvTabSelection === 'Tarif Wechsel'
                          ? 'Ändern'
                          : tvTabSelection === 'Tarif Kündigen'
                            ? 'Kündigen'
                            : lockUnlockText
                      }}
                    </v-btn>
                    <v-btn
                      color="red"
                      text
                      class="ma-2"
                      @click="cancelClose"
                    >
                      <v-icon
                        dark
                        right
                      >
                        mdi-cancel
                      </v-icon>
                      abbrechen
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-card-actions>
          </v-card>
          <v-overlay :value="loading">
            <v-progress-circular
              :size="120"
              color="primary"
              indeterminate
            ></v-progress-circular>
          </v-overlay>
        </v-dialog>
        <manually-order-dialog
          v-if="(userGroups.includes('camunda-admin') || userGroups.includes('LgSpotAuftragserfassung') || userGroups.includes('LgSpotHotlineLeitung'))"
          :loading-data="loadingData"
          :contract-data="contractData"
          :customer-id="customerId"
        />
        <order-history-dialog
          :customer-id="customerId"
          :loading-data="loadingData"
          hide-filter
        />
      </div>
    </DisplayContainer>
  </div>
</template>

<script>
import DisplayContainer from '@/components/elements/DisplayContainer.vue';
import DataDisplay from '@/components/elements/DataDisplay.vue';
import DatePicker from '@/components/elements/DatePicker.vue';
import ContactInformation from '@/components/ContactInformation.vue';
import ManuallyOrderDialog from '@/components/seloca/ManuallyOrderDialog';
import OrderHistoryDialog from '@/components/seloca/OrderHistoryDialog.vue';
import { HTTP } from '@/main/httpClient.js';
import NotificationObject from '@/main/NotificationObject.js';
import ErrorMessageBuilder from '@/util/ErrorMessageBuilder.js';

import TimeUtility from '@/util/TimeUtility.js';
import { LocalDate } from '@js-joda/core';

export default {
  name: 'DisplayCustomerSearchMetaData',
  components: {
    DisplayContainer,
    DataDisplay,
    ContactInformation,
    DatePicker,
    ManuallyOrderDialog,
    OrderHistoryDialog
  },
  props: {
    customerId: { type: Number, required: true },
    crmSystem: { type: String, required: false, default: 'VarioCRM' },
    contractData: { type: Object, required: true },
    contractIdsProvisionedPhoneNumbers: { type: Array, required: true },
    contractIdsProductWithTv: { type: Array, required: true },
    activeProducts: { type: Map, required: true },
    loadingData: { type: Boolean, required: true }
  },
  data: () => ({
    loading: false,
    tab: null,
    radios: null,
    expand: false,
    radioGroup1: 1,
    selectedItem: 1,
    currentErrors: null,
    versand: ['kein Versand', 'Briefversand an Rechnungsadresse'],
    versandNeutral: [
      'kein Versand',
      'Versand per E-Mail oder Briefversand an Installationsadresse wenn keine E-Mail vorhanden'
    ],
    portingDate: null,
    tvVertragStatusSelection: 'TV Vertrag ändern',
    versandSelection: 'kein Versand',
    tvTabSelection: 'Tarif Wechsel',
    versandSelectionValue: false,
    tvVertragSelectionValue: false,
    dialog: false,
    serviceOrderDocDialog: false,
    waipuDialog: false,
    tvDialog: false,
    downgradeUpgradeDate: '',
    cancelDialog: false,
    zugangsdatenModel: 'Rufnummern',
    zugangsdaten: ['Rufnummern', 'Internet'],
    contractSelection: '',
    waipu: null,
    select: '',
    minDate: '',
    datePicker: '',
    datePickerCancel: '',
    today: null,
    currentVal: '',
    currentCancelVal: '',
    latestAllowedCancelDate: null,
    newPrice: null,
    tvProductSelection: '',
    newTarif: '',
    minDatePicker: null,
    metaData: {
      customerName: undefined,
      customerId: undefined,
      customerPassword: undefined,
      system: undefined,
      birthDate: undefined,
      accounts: [],
      iban: undefined,
      email: undefined,
      addresses: [],
      waipuTvList: []
    },
    tvProducts: [],
    tvProductList: [],
    productsDataLoading: false,
    contractIdsWithPhoneNumbers: [],
    tabItems: ['Tarif Wechsel', 'Tarif Kündigen', 'Tarif sperren/entsperren'],
    earliestAllowedPortingDate: null,
    latestAllowedPortingDate: null,
    tvVertragSelectionDefault: null
  }),
  computed: {
    userGroups() {
      return this.$auth.user().userRoles;
    },
    filteredTvProductList() {
      if (!this.tvVertragSelectionDefault) {
        return this.tvProductList;
      }
      const currentContractId = this.tvVertragSelectionDefault.contractId;
      const filteredProductList = this.tvProductList.filter(
        (product) => product.name !== this.activeProducts.get(currentContractId)
      );
      return filteredProductList;
    },
    currentProductPrice() {
      if (this.tvVertragSelectionDefault) {
        const currentContractId = this.tvVertragSelectionDefault.contractId;
        if (
          !this.tvProductList ||
          this.tvProductList.length === 0 ||
          !currentContractId
        ) {
          return undefined;
        }
        return this.tvProductList.find(
          (product) =>
            product.name === this.activeProducts.get(currentContractId)
        ).price;
      } else {
        return undefined;
      }
    },
    lockUnlockText() {
      return this.isWaipuLocked() ? 'entsperren' : 'sperren';
    },
    tvContractsWithPrices() {
      if (this.tvProductList && this.tvProductList.length > 0) {
        return this.contractIdsProductWithTv.map((contract) => {
          return {
            ...contract,
            activeProductPrice: this.tvProductList.find(
              (item) => item.name === contract.activeProduct
            ).price
          };
        });
      } else {
        return [];
      }
    }
  },
  watch: {
    filteredTvProductList(newValue, oldValue) {
      if (newValue && newValue.length > 0) {
        this.select = newValue[0];
        this.onChangeTarif();
      }
    },
    tvContractsWithPrices(newValue, oldvalue) {
      if (newValue && newValue.length > 0) {
        this.tvVertragSelectionDefault = newValue[0];
      }
    }
  },
  mounted: function () {
    this.loadUserMetaData(this.customerId, this.crmSystem);
    this.loadDataTvProducts();
    this.today = LocalDate.now();
    this.minDatePicker = this.today.plusDays(30);
    this.minDate = this.today.toString();
    this.datePickerCancel = this.today.plusDays(30).toString();
  },
  methods: {
    showMessage: function (type, description) {
      this.$store.commit(
        'addNotification',
        new NotificationObject(type, description)
      );
    },
    buildErrorMessagePortingDate: function (portingDate) {
      if (portingDate == null || portingDate === '') {
        return 'Portierungsdatum muss gesetzt sein.';
      } else {
        return null;
      }
    },
    groupArrayOfObjects(list, key) {
      return list.reduce(function (rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
      }, {});
    },
    getCustomerName(firstName, lastName, company) {
      var name;
      if (firstName && lastName) {
        name = lastName + ', ' + firstName;
      }
      if (firstName && !lastName) {
        name = firstName;
      }
      if (lastName && !firstName) {
        name = lastName;
      }
      if (name && company) {
        name = name + ' / ' + company;
      } else if (!name && company) {
        name = company;
      }
      return name;
    },
    mapToMetaData(data) {
      this.metaData.customerName =
        this.getCustomerName(data.firstName, data.lastName, data.company) ||
        '-';
      this.metaData.customerId = data.customerId || '-';
      this.metaData.customerPassword = data.customerPassword || '-';
      this.metaData.email = data.email || '-';
      this.metaData.system = data.system || '-';
      this.metaData.birthDate = this.formatDate(
        data.installationAddress?.birthDate
      );
      this.metaData.accounts = data.accountInformation.map(
        (item) => item?.iban || 'Auf Rechnung'
      );
      this.metaData.addresses = data.addresses;
      this.metaData.addresses.map((item) => {
        item.nameFirma =
          item.fullName !== ' ' && item.fullName ? item.fullName : item.company;
        if (item.addressType === 'INVOICE') {
          item.addressType = 'Rechnungsadresse';
        } else if (item.addressType === 'INSTALLATION') {
          item.addressType = 'Installationsadresse';
        } else if (item.addressType === 'PHONEBOOK') {
          item.addressType = 'Telefonbuchadresse';
        } else if (item.addressType === 'CONTACT') {
          item.addressType = 'Kontaktadresse';
        } else if (item.addressType === 'DELIVERY') {
          item.addressType = 'Versandadresse';
        } else if (item.addressType === 'CONNECTION_OWNER') {
          item.addressType = 'Hausbesitzeradresse';
        }
      });
      this.metaData.waipuTvList = data.waipuTVDtoList;
    },

    loadDataTvProducts: function () {
      this.productsDataLoading = true;
      HTTP.get('products/television?command=TV-Haupt')
        .then((res) => {
          this.tvProductList = res.data;
        })
        .catch((e) => {})
        .finally(() => {
          this.productsDataLoading = false;
        });
    },
    loadUserMetaData: function (customerId, crmSystem) {
      HTTP.get(`/customer/meta/${customerId}?crmSystem=${crmSystem}`)
        .then((res) => {
          this.mapToMetaData(res.data);
        })
        .catch((e) => {})
        .finally(() => {});
    },
    formatDate(date) {
      return date ? TimeUtility.formatLocalDate(date) : '-';
    },
    close() {
      this.dialog = false;
    },
    closeServiceOrderDoc() {
      this.serviceOrderDocDialog = false;
    },
    tvClose() {
      this.tvDialog = false;
    },
    cancelClose() {
      this.tvDialog = true;
      this.cancelDialog = false;
    },
    onChangeTarif() {
      if (this.select) {
        this.newPrice = this.select.price;
      } else {
        return;
      }

      if (this.currentProductPrice < this.newPrice) {
        this.newTarif = 'Upgrade';
      } else {
        this.newTarif = 'Downgrade';
      }
      this.today = LocalDate.now();
      this.datePicker =
        this.newTarif === 'Downgrade' && this.today
          ? this.today.plusDays(30).toString()
          : this.today.toString();
    },
    tabChangeValue(tabItem) {
      this.tvTabSelection = tabItem;
    },
    createServiceOrderConfirmationDocuments() {
      this.loading = true;
      this.contractId = this.contractSelection.split(' ')[0];
      this.versandSelectionValue = this.versandSelection !== 'kein Versand';
      HTTP.post(
        `/contractDocument/serviceOrderConfirmationDocuments/${this.contractId}?sendDocuments=${this.versandSelectionValue}`,
        {}
      )
        .catch((err) => {
          this.showMessage(
            'error',
            ErrorMessageBuilder.buildErrorMessage(
              err.response.data,
              'Etwas ist bei der Generierung der Auftragsbestätigung / Produktzusammenfassung fehlgeschlagen'
            )
          );
        })
        .finally(() => {
          this.loading = false;
          this.closeServiceOrderDoc();
          this.$emit('clickedSaveButton');
          this.close();
        });
    },
    save() {
      this.loading = true;
      this.contractId = this.contractSelection.split(' ')[0];
      this.versandSelectionValue = this.versandSelection !== 'kein Versand';
      HTTP.post(
        `/contractDocument/credentials/${this.contractId}?sendCredentials=${this.versandSelectionValue}`,
        {}
      )
        .catch((err) => {
          this.showMessage(
            'error',
            ErrorMessageBuilder.buildErrorMessage(
              err.response.data,
              'Etwas ist bei der Generierung der Zugangsdaten fehlgeschlagen'
            )
          );
        })
        .finally(() => {
          this.loading = false;
          this.$emit('clickedSaveButton');
          this.close();
        });
    },
    isWaipuLocked() {
      if (!this.tvVertragSelectionDefault) {
        return false;
      }
      if (this.metaData.waipuTvList.length > 0) {
        return this.metaData.waipuTvList.find(
          (waipuProduct) =>
            waipuProduct.contractId.toString() ===
            this.tvVertragSelectionDefault.contractId
        ).locked;
      }
      return false;
    },
    lockWaipu() {
      this.cancelDialog = false;
      this.tvDialog = false;
      this.loading = true;
      const contractId = this.tvVertragSelectionDefault.contractId;
      HTTP.post(`/waiputv/lock/${this.customerId}`, {})
        .then((resp) => {
          this.loading = false;
          this.$store.commit(
            'addNotification',
            new NotificationObject(
              'success',
              `Das Waipu.tv Produkt im Vertrag ${contractId} wurde erfolgreich gesperrt.`
            )
          );
          this.loadUserMetaData(this.customerId, this.crmSystem);
        })
        .catch((e) => {
          this.$store.commit(
            'addNotification',
            new NotificationObject(
              'error',
              ErrorMessageBuilder.buildErrorMessage(
                e.response.data,
                'Der Waipu-Tarif konnte nicht gesperrt werden.'
              )
            )
          );
        })
        .finally(() => {
          this.loading = false;
          this.tvClose();
        });
    },
    unlockWaipu() {
      this.cancelDialog = false;
      this.tvDialog = false;
      this.loading = true;
      const contractId = this.tvVertragSelectionDefault.contractId;

      HTTP.post(`/waiputv/unlock/${this.customerId}`, {})
        .then((resp) => {
          this.loading = false;
          this.$store.commit(
            'addNotification',
            new NotificationObject(
              'success',
              `Das Waipu.tv Produkt im Vertrag ${contractId} wurde erfolgreich entsperrt.`
            )
          );
          this.loadUserMetaData(this.customerId, this.crmSystem);
        })
        .catch((e) => {
          this.$store.commit(
            'addNotification',
            new NotificationObject(
              'error',
              ErrorMessageBuilder.buildErrorMessage(
                e.response.data,
                'Der Waipu-Tarif konnte nicht entsperrt werden.'
              )
            )
          );
        })
        .finally(() => {
          this.loading = false;
          this.tvClose();
        });
    },
    tvSave() {
      const contractId = this.tvVertragSelectionDefault.contractId;
      if (this.tvTabSelection === 'Tarif Wechsel') {
        this.cancelDialog = false;
        this.tvDialog = false;
        this.loading = true;
        const changeTvContract = {
          contractId: contractId,
          executionDate: this.datePicker,
          productIdDto: {
            productId: this.select.id
          }
        };

        HTTP.post('/waiputv/changeProduct', changeTvContract)
          .then((resp) => {
            this.$store.commit(
              'addNotification',
              new NotificationObject(
                'success',
                `Die Produktänderung zu ${this.select.name} für Vertrag ${contractId} wurde erfolgreich beauftragt.`
              )
            );
            this.$emit('reloadProcesses');
          })
          .catch((e) => {
            this.$store.commit(
              'addNotification',
              new NotificationObject(
                'error',
                ErrorMessageBuilder.buildErrorMessage(
                  e.response.data,
                  'Die Tarifänderung konnte nicht beauftragt werden.'
                )
              )
            );
          })
          .finally(() => {
            this.loading = false;
            this.tvClose();
          });
      } else if (this.tvTabSelection === 'Tarif Kündigen') {
        this.cancelDialog = false;
        HTTP.delete(
          `/waiputv/unsubscribe?contractId=${contractId}&executionDate=${this.datePickerCancel}`,
          {}
        )
          .then(() => {
            this.$store.commit(
              'addNotification',
              new NotificationObject(
                'success',
                `Die Kündigung des Tarifs ${this.select.name} für Vertrag ${contractId} wurde erfolgreich beauftragt.`
              )
            );
            this.$emit('reloadProcesses');
          })
          .catch((e) => {
            this.$store.commit(
              'addNotification',
              new NotificationObject(
                'error',
                ErrorMessageBuilder.buildErrorMessage(
                  e.response.data,
                  'Die Kündigung konnte nicht beauftragt werden.'
                )
              )
            );
          })
          .finally(() => {
            this.loading = false;
            this.cancelClose();
            this.tvClose();
          });
      } else if (this.tvTabSelection === 'Tarif sperren/entsperren') {
        this.lockUnlockText === 'entsperren'
          ? this.unlockWaipu()
          : this.lockWaipu();
      }
    }
  }
};
</script>

<style>
.bold {
  font-weight: bold;
}
.size {
  font-size: 15px;
}
.gap {
  column-gap: 40px;
}
.cancel-dialog {
  flex-direction: row-reverse;
}
.padding {
  padding-bottom: 40px;
  padding-top: 40px;
}
</style>
